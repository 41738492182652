import { Col, Container, Row } from "react-bootstrap";
import Header from "../../comman/header";
import Footer from "../../comman/footer";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import CommonMeta from "../../comman/CommonMeta";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const BoardMembers = () => {
  // for lang change
  const { t } = useTranslation();

  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd Board Members"
        description="Meet the dedicated board members driving innovation and excellence at Poddar Pigments Ltd."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Board Members"
        type="article"
      />
      <Header />
      <div className="top-banner-image about-tabs board-member-bg">
        <div className="overlay"></div>
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1>{t("Board Members")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/about-us">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("About-Us")}</h4>
                      ) : (
                        <>
                          <h4>{t("About-Us")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/the-company">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("The Company")}</h4>
                      ) : (
                        <>
                          <h4>{t("The Company")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/csr-initiatives">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("CSR Initiatives")}</h4>
                      ) : (
                        <>
                          <h4> {t("CSR Initiatives")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/locations">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Locations")}</h4>
                      ) : (
                        <>
                          <h4>{t("Locations")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Board Members")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="board-member-leadership2">
        <Container>
          <Row>
            <p>
              {t(
                "Meet the distinguished leaders who drive Poddar Pigments toward its goals:"
              )}
            </p>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="board-leadership-wrapper">
                <div className="box">
                  <div className="inner-box">
                    <div className="left-box">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.SSPoddar2}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="name-details">
                        <h3>Shri S. S. Poddar</h3>
                        {/* <h4> Designation 1</h4> */}
                      </div>
                    </div>
                    <div className="details">
                      <ul>
                        <li>
                          {t("Promoter and Managing Director of the company.")}{" "}
                        </li>
                        <li>
                          {t(
                            "An Engineering graduate with over 46 years of industrial experience."
                          )}
                        </li>
                        <li>
                          {t(
                            "Under his leadership, the company has made substantial progress."
                          )}
                        </li>
                        <li>
                          {t(
                            "He is also a Director in Ambattur Enterprises Pvt. Ltd. and Express Publications (Madurai) Pvt. Ltd."
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <div className="left-box">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.GauravGoenka}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="name-details">
                        <h3>Shri Gaurav Goenka</h3>
                      </div>
                    </div>
                    <div className="details">
                      <ul>
                        <li>
                          {t(
                            "Joint Managing Director & Chief Executive Officer of the Company."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "A Boston University graduate with extensive expertise in manufacturing and project implementation."
                          )}
                        </li>
                        <li>
                          {t(
                            "Having more than 25 years of experience in the field of Foreign Trade, Marketing, Business Administration and other allied activities related to operation and management of large corporates."
                          )}
                        </li>
                        <li>
                          {t(
                            "He is also a Director in Siddhartha Apparels Pvt. Ltd., Adroit Urban Developers Pvt. Ltd., and Silver Crest Clothing Pvt. Ltd."
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <div className="left-box">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.MahimaPAgarwal}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="name-details">
                        <h3>Smt. Mahima P. Agarwal</h3>
                      </div>
                    </div>
                    <div className="details">
                      <ul>
                        <li>
                          {t(
                            "Non-Executive Director of the company since 2017."
                          )}
                        </li>
                        <li> {t("B. Arch. from Anna University, Chennai.")}</li>
                        <li>
                          {t(
                            "Brings 17 years of experience in Foreign Trade, Taxation & Accounting."
                          )}
                        </li>
                        <li>
                          {t(
                            "She is also a Director in GKS Holdings Pvt. Ltd. and Surya Ojas Solar Pvt. Ltd."
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <div className="left-box">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.Mahadevan}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="name-details">
                        <h3>Shri M. Mahadevan</h3>
                      </div>
                    </div>
                    <div className="details">
                      <ul>
                        <li>
                          {t(
                            "Non-executive Independent Director of the Company since 2016."
                          )}
                        </li>
                        <li> {t("Commerce Graduate.")}</li>
                        <li>
                          {t(
                            "A seasoned entrepreneur in the global hospitality industry, with over 25 years of experience."
                          )}
                        </li>
                        <li>
                          {t(
                            "He is also a Director in CC Food Network Pvt. Ltd., B & M Hotbreads Pvt. Ltd., Advantage Foods Pvt. Ltd. and Oriental Bakery Pvt. Ltd."
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <div className="left-box">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.bhandariJi}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="name-details">
                        <h3>Shri Gajendra Kumar Bhandari</h3>
                      </div>
                    </div>
                    <div className="details">
                      <ul>
                        <li>
                          {t(
                            "Non-Executive Independent Director of the Company from 2023."
                          )}
                        </li>
                        <li>
                          {t(
                            "B.Sc. in Electrical Engineering from Ranchi University."
                          )}
                        </li>
                        <li>
                          {t(
                            "Having more than 40 years experience in manufacturing business related to Chemical Industries, Glass Industries, etc."
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="inner-box">
                    <div className="left-box">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.RMohan}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="name-details">
                        <h3>Shri R. Mohan</h3>
                      </div>
                    </div>
                    <div className="details">
                      <ul>
                        <li>
                          {t(
                            "Non-Executive Independent Director of the Company from 2023."
                          )}
                        </li>
                        <li>{t("Chartered Accountant.")}</li>
                        <li>
                          {t(
                            "A professional with over 32 years experience in Audit, Taxation and Consultancy."
                          )}
                        </li>
                        <li>
                          {t(
                            "He is also a Director in Lambodhara Textiles Ltd. and HNM Rubber Products Pvt. Ltd."
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};
export default BoardMembers;
